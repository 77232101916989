export const AboutAccountsLink = 'https://docs.uipath.com/automation-suite/{language}/docs/about-accounts';

export const AboutAccountsLinkOrchestrator = 'https://docs.uipath.com/{language}/orchestrator/docs/account-types';

export const GroupsAndRolesLink =
  'https://docs.uipath.com/automation-suite/automation-suite/{version}/admin-guide/about-accounts';

export const TenantsAndServicesLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/managing-tenants-and-services';

export const BrowserMigrationLink = 'https://docs.uipath.com/automation-cloud/{language}/docs/using-the-migration-tool';

export const UserManagementLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/about-accounts#user-accounts';

export const LicensingPlansLink = 'https://docs.uipath.com/automation-suite/{language}/docs/about-licensing';

export const LicensingOverallocationLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/license-overallocation';

export const UserLicenseManagementLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/user-license-management';

export const RegionInstancesLink = 'https://docs.uipath.com/automation-cloud/{language}/docs/regions-and-instances';

export const LicensingRobotServicesLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/about-licensing#types-of-robot-licenses';

export const ManagingUsersBulkAddingLink =
  'https://docs.uipath.com/automation-cloud/{language}/docs/managing-users#bulk-adding-users';

export const ReleaseNotesLink = 'https://docs.uipath.com/releasenotes/{language}/';

export const OrchestratorLink = 'https://docs.uipath.com/orchestrator/{language}/';

export const RobotLink = 'https://docs.uipath.com/robot/{language}/';

export const StudioLink = 'https://docs.uipath.com/studio/standalone/{version}';

export const ActivitiesLink = 'https://docs.uipath.com/activities/{language}/';

export const AIFabricLink = 'https://docs.uipath.com/ai-fabric/{language}//v0/docs/about-ai-fabric';

export const IntegrationGuideLink = 'https://docs.uipath.com/marketplace/{language}/';

export const TenantRegionDocsLinks = 'https://docs.uipath.com/{language}/overview-guide/docs/data-residency-cloud';

export const AuthenticationAndSecuritySettingsLinks =
  'https://docs.uipath.com/automation-suite/{language}/docs/global-authentication-settings';

export const LicensingManagementOptionsLink = 'https://docs.uipath.com/overview-guide/{language}/docs/license-management-options';

export const EnablingDisablingServicesLink =
  'https://docs.uipath.com/automation-suite/{language}/docs/managing-tenants-and-services#enabling-or-disabling-services';

export const LeavingOrganizationLink = 'https://docs.uipath.com/automation-cloud/{language}/docs/user-preferences#leaving-an-organization';

export const EncryptionLink = 'https://docs.uipath.com/automation-cloud/{language}/docs/encryption';

export const CustomerManagedKeyBestPracticesLink =
  'https://docs.uipath.com/automation-cloud/{language}/docs/encryption#best-practices-for-using-customer-managed-keys';

export const CustomerManagedKeySwitchLink =
  'https://docs.uipath.com/automation-cloud/docs/encryption#switch-from-customer-managed-to-uipath-managed-keys';

export const IPRestrictionLink = 'https://docs.uipath.com/automation-cloud/{language}/docs/restricting-access-by-ip';

export const SessionPolicyLink = 'https://docs.uipath.com/automation-cloud/{language}/docs/session-policy';

export const PortalTagManagementOrganizingResourcesLink =
  'https://docs.uipath.com/orchestrator/{language}/v2023.4/docs/managing-tags';

export const AutomationSuiteDocumentationLink = 'https://docs.uipath.com/automation-suite/automation-suite/{version}';

