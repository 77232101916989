import { GlobalStyles } from '@experiences/theme';
import { useShowDialog } from '@experiences/util';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
    ReadArticleLinkCloud,
    ReadArticleLinkOnPrem,
} from '../../../common/constants/documentation/SAMLDocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { AuthenticationSettingType } from '../../../services/identity/AuthenticationSettingService';

const useStyles = makeStyles((theme) => ({ ...GlobalStyles(theme) }));

type AuthenticationSettingTypeKeys = keyof typeof AuthenticationSettingType;
type AuthenticationSettingTypeValues = typeof AuthenticationSettingType[AuthenticationSettingTypeKeys];

export const useShowSuccessConfirmationDialog = (provider: AuthenticationSettingTypeValues) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const isAAD = useMemo(
        () => provider === AuthenticationSettingType.AAD,
        [ provider ],
    );

    const createDialog = useShowDialog();

    const getLocalizedLink = useDocumentationLinks({
        product: process.buildConfigs.documentationProduct,
        version: process.buildConfigs.documentationVersion,
    });

    return async () => createDialog({
        title: translate(
            { id: 'CLIENT_AUTH_SETTINGS_SSO_ENABLED_SUCCESSFULLY' },
            { ssoType: translate({ id: isAAD ? 'CLIENT_AZURE_AD' : 'CLIENT_SAML_SSO' }) },
        ),
        showCancel: isAAD,
        primaryButtonText: translate({ id: isAAD ? 'CLIENT_SIGN_OUT' : 'CLIENT_DONE' }),
        cancelButtonText: isAAD ? translate({ id: 'CLIENT_DO_IT_LATER' }) : undefined,
        body: translate(
            { id: `CLIENT_AUTH_SETTINGS_${provider?.toUpperCase()}_ENABLED_SUCCESSFULLY` },
            {
                readArticleLink: (
                    <a
                        className={classes.a}
                        href={getLocalizedLink(
                            process.buildConfigs.documentationProduct === 'automation-cloud'
                                ? ReadArticleLinkCloud
                                : ReadArticleLinkOnPrem,
                        )}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {translate({ id: `CLIENT_AUTH_SETTINGS_READ_THIS_ARTICLE${isAAD ? '_CAPS' : ''}` })}
                    </a>
                ),
            },
        ),
    });
};
