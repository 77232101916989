import type { LanguageCulture } from '@experiences/locales';
import {
    getLanguageForDocLinks,
    useLocalization,
} from '@experiences/locales';
import {
    useCallback,
    useMemo,
} from 'react';

interface ILinkOption {
    overrideLanguage?: LanguageCulture;
    excludedLanguages?: string[];
    product?: 'orchestrator' | 'automation-cloud' | 'automation-suite';
    version?: string;
}

export const useDocumentationLinks = (options?: ILinkOption) => {
    const languageStorage = useLocalization();
    const language = useMemo(() => options?.excludedLanguages && options.excludedLanguages.includes(languageStorage) ? 'en' : languageStorage, [ options, languageStorage ]);
    const getLocalizedLink = useCallback(
        (link: string) => {
            let parsedLink = link
                .replace('{version}/', options?.version ? options.version + '/' : '')
                .replace('{version}', options?.version ? options.version : '')
                .replace('{language}/', options?.overrideLanguage ?? getLanguageForDocLinks(language));
            if (options?.product) {
                parsedLink = parsedLink.replace('{product}/', options?.product + '/');
            }
            return parsedLink;
        },
        [ language, options ],
    );

    return getLocalizedLink;
};
