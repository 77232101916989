import { GlobalStyles } from '@experiences/theme';
import { useFormKeysValidation } from '@experiences/util';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
    Checkbox,
    FormControlLabel,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { AllowedDomainsAndAttributeMapping } from '../../../common/constants/documentation/SAMLDocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        pageDescription: {
            marginBottom: '20px',
            maxWidth: '520px',
        },
        field: {
            marginBottom: '16px',
            maxWidth: '420px',
        },
        fieldLabel: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
        },
        formSection: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelWithHelp: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '15px',
        },
        sectionLabel: {
            fontWeight: 600,
            lineHeight: '20px',
        },
        infoIcon: {
            width: '18px',
            height: '18px',
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
        },
        tooltip: { display: 'flex' },
        tooltipIcon: {
            width: '16px',
            height: '16px',
            marginLeft: '4px',
        },
        checkboxField: {
            maxWidth: '450px',
            marginTop: '-10px',
        },
    }),
}));

const SecuritySettingsSAMLProvisioningSettings: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({
        product: process.buildConfigs.documentationProduct,
        version: process.buildConfigs.documentationVersion,
    });

    const {
        register, control, errors, setValue,
    } = useFormContext<ISamlFormData>();

    const activeKeys = useMemo(() => [ 'ProvisioningSetting.AllowedDomains', 'ProvisioningSetting.AttributeMapper.DisplayName' ], []);

    useFormKeysValidation(activeKeys);

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    return (
        <div className={classes.formSection}>
            <Typography className={classes.pageDescription}>
                {translate({ id: 'CLIENT_AUTH_SETTINGS_SAML_PROVISIONING_DESCRIPTION' })}
            </Typography>
            <div className={classes.labelWithHelp}>
                <Typography className={classes.sectionLabel}>
                    {translate({ id: 'CLIENT_ALLOWED_DOMAINS' })}
                </Typography>
                <Tooltip
                    arrow
                    title={
                        <div className={classes.tooltip}>
                            {translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELP_TEXT' })}
                            <OpenInNewIcon className={classes.tooltipIcon} />
                        </div>
                    }
                >
                    <a
                        className={classes.a}
                        href={getLocalizedLink(AllowedDomainsAndAttributeMapping)}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELP_TEXT' })}
                    >
                        <HelpOutlineIcon className={classes.infoIcon} />
                    </a>
                </Tooltip>
            </div>
            <Controller
                as={TextField}
                control={control}
                rules={{ required: true }}
                label={translate({ id: 'CLIENT_ALLOWED_DOMAINS_INSTRUCTIONS' })}
                InputLabelProps={{ id: 'allowedDomainsLabel' }}
                inputProps={{ 'aria-labelledby': 'allowedDomainsLabel' }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AllowedDomains}
                helperText={
                    errors.ProvisioningSetting?.AllowedDomains?.type === 'required' &&
                    translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                }
                name="ProvisioningSetting.AllowedDomains"
                data-cy="configure-jit-allowed-domains-field"
            />
            <Controller
                name="ProvisioningSetting.AccountLinkConfirmation"
                control={control}
                rules={{ required: true }}
                helperText={
                    errors.ProvisioningSetting?.AccountLinkConfirmation?.type === 'required' &&
                    translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                }
                render={props => (
                    <FormControlLabel
                        className={classes.checkboxField}
                        control={
                            <Checkbox
                                checked={props.value}
                                onChange={e =>
                                    handleSwitch('ProvisioningSetting.AccountLinkConfirmation', e.target.checked, props.onChange)}
                                color="primary"
                                data-cy="configure-jit-account-link-confirmation-checkbox"
                            />
                        }
                        label={<div>
                            {translate({ id: 'CLIENT_AUTH_SETTINGS_SAML2_CONFIRMATION' })}
                        </div>}
                    />
                )}
            />

            <div className={classes.labelWithHelp}>
                <Typography className={classes.sectionLabel}>
                    {translate({ id: 'CLIENT_ATTRIBUTE_MAPPING' })}
                </Typography>
                <Tooltip
                    arrow
                    title={
                        <div style={{ display: 'flex' }}>
                            {translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_HELP_TEXT' })}
                            <OpenInNewIcon className={classes.tooltipIcon} />
                        </div>
                    }
                >
                    <a
                        className={classes.a}
                        href={getLocalizedLink(AllowedDomainsAndAttributeMapping)}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_HELP_TEXT' })}
                    >
                        <HelpOutlineIcon className={classes.infoIcon} />
                    </a>
                </Tooltip>
            </div>
            <TextField
                className={classes.field}
                inputRef={register({
                    required: true,
                    minLength: 1,
                    maxLength: 256,
                    validate: p => !!p.trim(),
                })}
                name="ProvisioningSetting.AttributeMapper.DisplayName"
                required
                variant='outlined'
                fullWidth
                error={!!errors.ProvisioningSetting?.AttributeMapper?.DisplayName}
                inputProps={{ 'aria-label': translate({ id: 'CLIENT_DISPLAY_NAME' }) }}
                label={
                    <div className={classes.fieldLabel}>
                        {translate({ id: 'CLIENT_DISPLAY_NAME' })}
                        <Tooltip
                            title={translate({ id: 'CLIENT_DISPLAY_NAME_TOOLTIP' })}>
                            <PortalCustomIcon
                                name='info'
                                size='15px' />
                        </Tooltip>
                    </div>
                }
                data-cy="configure-jit-display-name-field"
                helperText={
                    errors.ProvisioningSetting?.AttributeMapper?.DisplayName?.type === 'required' &&
                            translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_DISPLAY_NAME' }) },
                            )
                }
            />
            <Controller
                as={TextField}
                control={control}
                rules={{ maxLength: 256 }}
                label={translate({ id: 'CLIENT_FIRST_NAME' })}
                InputLabelProps={{ id: 'firstNameLabel' }}
                inputProps={{ 'aria-labelledby': 'firstNameLabel' }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                name="ProvisioningSetting.AttributeMapper.FirstName"
                error={!!errors.ProvisioningSetting?.AttributeMapper?.FirstName}
                data-cy="configure-jit-first-name-field"
            />
            <Controller
                as={TextField}
                control={control}
                rules={{ maxLength: 256 }}
                label={translate({ id: 'CLIENT_LAST_NAME' })}
                InputLabelProps={{ id: 'firstNameLabel' }}
                inputProps={{ 'aria-labelledby': 'lastNameLabel' }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                name="ProvisioningSetting.AttributeMapper.LastName"
                error={!!errors.ProvisioningSetting?.AttributeMapper?.LastName}
                data-cy="configure-jit-last-name-field"
            />
            <Controller
                as={TextField}
                control={control}
                label={translate({ id: 'CLIENT_JOB_TITLE' })}
                InputLabelProps={{ id: 'jobTitleLabel' }}
                inputProps={{ 'aria-labelledby': 'jobTitleLabel' }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AttributeMapper?.JobTitle}
                name="ProvisioningSetting.AttributeMapper.JobTitle"
                data-cy="configure-jit-job-title-field"
            />
            <Controller
                as={TextField}
                control={control}
                label={translate({ id: 'CLIENT_DEPARTMENT' })}
                InputLabelProps={{ id: 'departmentLabel' }}
                inputProps={{ 'aria-labelledby': 'departmentLabel' }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AttributeMapper?.Department}
                name="ProvisioningSetting.AttributeMapper.Department"
                data-cy="configure-jit-department-field"
            />
            <Controller
                as={TextField}
                control={control}
                label={translate({ id: 'CLIENT_CITY' })}
                InputLabelProps={{ id: 'cityLabel' }}
                inputProps={{ 'aria-labelledby': 'cityLabel' }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AttributeMapper?.City}
                name="ProvisioningSetting.AttributeMapper.City"
                data-cy="configure-jit-city-field"
            />

        </div>
    );
};

export default SecuritySettingsSAMLProvisioningSettings;
