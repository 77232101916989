import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiProgressButton } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
    ExternalUserMappingStrategy,
    Saml2BindingType,
    Usage,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';
import type { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import {
    AuthenticationSettingType,
    BulkAuthenticationSettingKey,
    createDirectoryIntegrationSettingSaml2,
    updateDirectoryIntegrationSettingSaml2,
} from '../../../services/identity/AuthenticationSettingService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../store/selectors';
import { mapSamlFormDataToAuthSettingPayload } from '../../../util/setting/AuthSettingUtil';
import { useShowSuccessConfirmationDialog } from '../../authsettings/subcomponents/useShowSuccessConfirmationDialog';
import UiForm from '../../common/UiForm';
import SecuritySettingsSAMLAdvancedDetailsForm from './SecuritySettingsSAMLAdvancedDetails';
import SecuritySettingsSAMLFormStepper from './SecuritySettingsSAMLFormStepper';
import SecuritySettingsSAMLGeneralForm from './SecuritySettingsSAMLGeneralForm';
import SecuritySettingsSAMLProvisioningSettings from './SecuritySettingsSAMLProvisioningSettings';

const useStyles = makeStyles(theme =>
    createStyles({
        visibleForm: {
            width: 'auto',
            paddingBottom: '64px',
            opacity: 1,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        footerButton: {
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '8px',
            '& > a': {
                color: theme.palette.semantic.colorForegroundInverse,
                'text-decoration': 'none !important',
            },
        },
    }),
);

const SecuritySettingsSAMLForm: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const openShowSuccessConfirmationDialog = useShowSuccessConfirmationDialog(AuthenticationSettingType.SAML);

    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);

    const disableForm = useMemo(() => !isAdmin, [ isAdmin ]);

    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();
    const handleError = useCallback(
        async error => setErrorMessage(await getErrorMessage(error)),
        [ getErrorMessage, setErrorMessage ],
    );

    const bulkAuthenticationSetting = useCheckAuthenticationSetting();

    const authenticationSetting = bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML];

    const [ activeStep, setActiveStep ] = useState(0);

    const externalIdentityProviderDtoSettings: ISaml2ProviderSettings | undefined = useMemo(() => {
        if (!authenticationSetting?.externalIdentityProviderDto?.settings) {
            return undefined;
        }

        try {
            return JSON.parse(authenticationSetting.externalIdentityProviderDto.settings) as ISaml2ProviderSettings;
        } catch (error) {
            handleError(error);
        }
    }, [ authenticationSetting, handleError ]);

    const formMethods = useForm<ISamlFormData & { activeKeys: string[] }>({
        mode: 'onChange',
        shouldUnregister: false,
        defaultValues: {
            activeKeys: [],
            SingleSignOnServiceUrl: '',
            ServiceProviderEntityId: '',
            IdentityProviderEntityId: '',
            SigningCertificateLocation: { CertificateText: '' },
            IdentityProviderMetadataUrl: '',
            AllowUnsolicitedAuthnResponse: true,
            ExternalAuthUserMappingStrategy: ExternalUserMappingStrategy.ByUserEmail,
            Saml2BindingType: Saml2BindingType.HttpRedirect,
            ServiceCertificateUsage: Usage.SigningAndEncryption,
            ProvisioningSetting: {
                AccountLinkConfirmation: false,
                AllowedDomains: '',
                AttributeMapper: {
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    JobTitle: '',
                    City: '',
                    Department: '',
                    DisplayName: '',
                },
            },
        },
    });

    const {
        handleSubmit, reset, trigger, formState, watch, clearErrors,
    } = formMethods;

    const { errors } = formState;

    useEffect(() => {
        if (externalIdentityProviderDtoSettings) {
            reset({
                activeKeys: [],
                SingleSignOnServiceUrl: externalIdentityProviderDtoSettings?.SingleSignOnServiceUrl ?? '',
                ServiceProviderEntityId: externalIdentityProviderDtoSettings?.ServiceProviderEntityId ?? '',
                IdentityProviderEntityId: externalIdentityProviderDtoSettings?.IdentityProviderEntityId ?? '',
                SigningCertificateLocation: {
                    CertificateText: externalIdentityProviderDtoSettings?.SigningCertificateLocation?.CertificateText
                    ?? '',
                },
                IdentityProviderMetadataUrl: externalIdentityProviderDtoSettings?.IdentityProviderMetadataUrl ?? '',
                AllowUnsolicitedAuthnResponse:
                    externalIdentityProviderDtoSettings?.AllowUnsolicitedAuthnResponse != null
                        ? externalIdentityProviderDtoSettings?.AllowUnsolicitedAuthnResponse
                        : true,
                ExternalAuthUserMappingStrategy:
                    externalIdentityProviderDtoSettings?.ExternalAuthUserMappingStrategy ?? ExternalUserMappingStrategy.ByUserEmail,
                Saml2BindingType: externalIdentityProviderDtoSettings?.Saml2BindingType ?? Saml2BindingType.HttpRedirect,
                ServiceCertificateUsage:
                    externalIdentityProviderDtoSettings?.ServiceCertificateUsage ?? Usage.SigningAndEncryption,
                ProvisioningSetting: {
                    AccountLinkConfirmation:
                        externalIdentityProviderDtoSettings?.ProvisioningSetting?.AccountLinkConfirmation ?? false,
                    AllowedDomains: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AllowedDomains?.join(', ') ?? '',
                    AttributeMapper: {
                        FirstName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.FirstName ?? '',
                        LastName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.LastName ?? '',
                        Email: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.Email ?? '',
                        JobTitle: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.JobTitle ?? '',
                        City: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.City ?? '',
                        Department: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.Department ?? '',
                        DisplayName: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper?.DisplayName ?? '',
                    },
                },
            });
        }
    }, [ authenticationSetting, externalIdentityProviderDtoSettings, reset ]);

    const [ loading, setLoading ] = useState(false);

    const cancel = useCallback(() => {
        history.push(getRoute(RouteNames.SecuritySettings));
    }, [ history, getRoute ]);

    const navigateToStep = useCallback(
        async (step: number) => {
            await trigger(watch('activeKeys'));

            if (Object.keys(errors).length && step > activeStep) {
                return;
            }

            if (step < activeStep) {
                clearErrors();
            }

            setActiveStep(step);
        },
        [ activeStep, clearErrors, errors, trigger, watch ],
    );

    const onSubmit = useCallback(
        async (data: ISamlFormData) => {
            setLoading(true);
            const payload = mapSamlFormDataToAuthSettingPayload(
                data,
                partitionGlobalId,
                authenticationSetting?.externalIdentityProviderDto?.id,
            );
            try {
                if (authenticationSetting?.authenticationSettingType?.toLowerCase() === AuthenticationSettingType.SAML) {
                    await updateDirectoryIntegrationSettingSaml2(payload);

                } else {
                    await createDirectoryIntegrationSettingSaml2(payload);
                }

                await openShowSuccessConfirmationDialog();

                history.push(getRoute(RouteNames.SecuritySettings));
            } catch (error) {
                await handleError(error);
            } finally {
                setLoading(false);
            }
        },
        [
            partitionGlobalId,
            authenticationSetting?.externalIdentityProviderDto?.id,
            authenticationSetting?.authenticationSettingType,
            openShowSuccessConfirmationDialog,
            history,
            getRoute,
            handleError,
        ],
    );

    return (
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    <Button
                        className={classes.footerButton}
                        onClick={cancel}
                        data-cy="saml-cancel-button">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    {activeStep > 0 && (
                        <Button
                            className={classes.footerButton}
                            onClick={() => navigateToStep(activeStep - 1)}
                            variant="outlined"
                            data-cy="saml-back-button"
                        >
                            {translate({ id: 'CLIENT_BACK' })}
                        </Button>
                    )}
                    {activeStep < 2 && (
                        <Button
                            className={classes.footerButton}
                            disabled={Object.keys(errors).length > 0}
                            onClick={() => navigateToStep(activeStep + 1)}
                            variant="outlined"
                            data-cy="saml-next-button"
                        >
                            {translate({ id: 'CLIENT_NEXT' })}
                        </Button>
                    )}
                    <UiProgressButton
                        type="submit"
                        className={classes.footerButton}
                        loading={loading}
                        disabled={disableForm || Object.keys(errors).length > 0}
                        variant="contained"
                        data-cy="saml-test-and-save-button"
                    >
                        {translate({ id: 'CLIENT_TEST_AND_SAVE' })}
                    </UiProgressButton>
                </div>
            }>
            <FormProvider {...formMethods}>
                <SecuritySettingsSAMLFormStepper activeStep={activeStep} />
                {activeStep === 0 && <div className={classes.visibleForm}>
                    <SecuritySettingsSAMLGeneralForm />
                </div>}
                {activeStep === 1 && <div className={classes.visibleForm}>
                    <SecuritySettingsSAMLProvisioningSettings />
                </div>}
                {activeStep === 2 && <div className={classes.visibleForm}>
                    <SecuritySettingsSAMLAdvancedDetailsForm />
                </div>}
            </FormProvider>
        </UiForm>
    );
};

export default SecuritySettingsSAMLForm;
